(function ($) {

  $(function(){

    /* accessible outline removal --------- */
    $('a, button').mousedown(function () {
      $(this).css('outline', 'none');
    });

    $('a, button').keydown(function () {
      $(this).css('outline', 'inherit');
    });


    /* nav/table of contents ------------ */

    // Determine which CSS 'transitionend' event is valid in browser (backwards compatibility)
    // (Function from David Walsh: http://davidwalsh.name/css-animation-callback)
    function whichTransitionEvent() {
      var t,
        el = document.createElement('fakeelement');

      var transitions = {
        'transition': 'transitionend',
        'OTransition': 'oTransitionEnd',
        'MozTransition': 'transitionend',
        'WebkitTransition': 'webkitTransitionEnd'
      };

      for (t in transitions) {
        if (el.style[t] !== undefined) {
          return transitions[t];
        }
      }
    }

    var transitionEvent = whichTransitionEvent();

    $('.accrdn--parent').on('click touch', function (e) {
      var clickedEl = $(this);

      // if this one's not open and other are, close others first then open this
      if ($(this).hasClass('closed') && $(this).siblings('.open').length) {

        $(this).siblings('.open').removeClass('open').addClass('closed').one(transitionEvent, function () {
          $(clickedEl).addClass('open').removeClass('closed');
        });
      }

      else {// if this one's open, close it; if none are open, just open this
        $(this).toggleClass('open').toggleClass('closed');
      }
    });

    // open TOC: after slides in, set 'main' element class so no scrolling
    // close TOC: remove 'main' element class then slide out

    $('.toc header').on('click touch', function (e) {
      e.preventDefault();
      var $toc = $('.toc'),
        $body = $('body');

      if ($toc.hasClass('closed')) {
        $toc.toggleClass('open').toggleClass('closed');

        $toc.one(transitionEvent, function () {
          $body.addClass('menu--open');
        });
      }

      else {
        $body.removeClass('menu--open');
        $toc.find('.accrdn--parent').removeClass('open').addClass('closed');
        $toc.toggleClass('open').toggleClass('closed');
      }
    });

    /* modals ---------------- */

    // object image modals
    $('.overlay-launch').on('click touch', function (e) {
      var $staticImgOverlay = $('.field-name-field-overlay-image');
      var $360ImgPlaceholder = $('.gardengnome-player-preview');

      if ($staticImgOverlay.length > 0) {
        var winHeight = window.innerHeight;

        $('.overlay__container--inner').css('height', winHeight - 80 + 'px');
        $staticImgOverlay.css('height', winHeight - 140 + 'px');
      }

      else if($360ImgPlaceholder.length > 0) {
        $360ImgPlaceholder.click(); // this will trigger gardengnome to replace placeholder with 360 if it hasn't been
      }

      $('.overlay').addClass('visible');
      $('main, body').addClass('modal');
    });

    $('.overlay .element__close').on('click touch', function (e) {
      $('.overlay').removeClass('visible');
      $('main, body').removeClass('modal');
    });




    // figure modals

    $('#main-content .field-name-field-inline-figure figure, #main-content .field-name-field-figure-references figure').on('click touch', function () {
      $(this).parent().addClass('overlay').addClass('visible');
      $('main, body').addClass('modal');
    });

    $('#main-content .field-name-field-inline-figure ').on('click touch', '.element__close', function () {
      $(this).parents('.field-name-field-inline-figure').removeClass('visible').removeClass('overlay');
      $('main, body').removeClass('modal');
    });

    $('#main-content .field-name-field-figure-references ').on('click touch', '.element__close', function () {
      $(this).parents('.field-name-field-figure-references').removeClass('visible').removeClass('overlay');
      $('main, body').removeClass('modal');
    });

    /* footnotes -------------- */

    $('.note-link').on('click touch', function (e) {
      e.preventDefault();
      var noteHref = this.href;
      var noteHrefArr = noteHref.split('/');
      var nodeNum = noteHrefArr.slice(-1);

      var noteId = 'note-' + nodeNum;
      var $noteObj = $('#' + noteId);

      if ($noteObj.hasClass('visible')) {  // if open note reference is clicked on, close it
        $('body').removeClass('note--visible');
        $noteObj.removeClass('visible');
      }

      else {
        var $visibleNote = $('.visible');

        if ($visibleNote.length > 0) { // if a different note is open, close it then open new one
          $($visibleNote).removeClass('visible');
          $($visibleNote).one(transitionEvent, function () {
            $noteObj.addClass('visible');
          });
        }

        else {
          $noteObj.addClass('visible');
          $('body').addClass('note--visible');
        }
      }
    });

    $('[id^="note-"]').find('.element__close').on('click touch', function (e) {
      e.preventDefault();
      $(this).parents('[id^="note-"]').removeClass('visible');
      $('body').removeClass('note--visible');
    });

    /* transcripts --------------- */
    $('.field-name-field-video').find('h3.field-label').on('click touch', function (e) {
      var $transcriptElem = $('.field-name-field-transcript');

      if ($transcriptElem.hasClass('visible')) {
        $(this).removeClass('visible');
        $transcriptElem.removeClass('visible');
      }

      else {
        $(this).addClass('visible');
        $transcriptElem.addClass('visible');
      }
    });

    /* help button/text area --------- */
    $('.help').on('click touch', function (e) {
      e.preventDefault();
      $(this).toggleClass('visible');
      $(this).siblings('.help-text').toggleClass('visible');
    });

    /* panzoom functionality -------- */
    var $pzContainer = $('.object__image-full').find('figure');

    if ($pzContainer.length) {
      $pzContainer.panzoom({
        minScale: 1,
        maxScale: 10,
        increment: 0.5,
        exponential: false,
        $zoomIn: $('.zoom--plus'),
        $zoomOut: $('.zoom--minus')
      });

      $pzContainer.panzoom('zoom', 1.5);

      $(window).on('orientationchange', function (e) {
        $pzContainer.panzoom('resetDimensions');
        $pzContainer.panzoom('reset');
      });

      $(window).on('unload', function(){
        localStorage.setItem('overlayPositioned', false);
      });
    }

  });

})(jq310);